import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/circleci/project/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { graphql } from "gatsby";
import { data, space } from '@entur/tokens';
import { Paragraph, StrongText, UnorderedList, ListItem } from '@entur/typography';
import { DoDontGroup, DoDontCard } from '~/components/DoDont';
import BaseCardDesignEntur from '~/components/BaseCardDesignEntur';
import PageHeader from '~/components/PageHeader';
import { ImageDisplay } from '~/components/ImageDisplay';
export const query = graphql`
  query pageQuery {
    pageHeader: file(
    sourceInstanceName: { eq: "media"}
    name: {
      eq: "Entur_visuell-identitet2"
    }
    ) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    principles: allFile(
      filter: {sourceInstanceName: {eq: "media"}, relativePath: {regex: "/.+design_prinsipper.+/"}}
    ) { 
      images: nodes {
        name
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  }
`;
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component '" + name + "' was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  query,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">






    <PageHeader mdxType="PageHeader" />
    <ImageDisplay fluidSource={props.data.pageHeader.childImageSharp.fluid} alt="Et bilde som viser Entur sin profil gjennom et foto av en dame kledd i 
      Enturs profilfarger på venstresiden og teksten «En resie, En billett, En app. 
      Entur» på høyresiden" style={{
      marginBottom: space.extraLarge3
    }} preset="full-width-image" mdxType="ImageDisplay" />
    <p>{`Målet med vår identitet er å sikre et helhetlig uttrykk på tvers av alle kanaler. Hvert eneste uttak
vi skaper med Entur som avsender bidrar til den overordnede merkevaren vår og hvordan vi blir oppfattet.
Det er derfor viktig at vi er konsekvente i bruk og omhyggelige med hvordan vi uttrykker oss, så
inntrykket av Entur blir opprettholdt. På den måten kan vi sammen skape en sterk og gjenkjennelig
merkevare som både tilbydere og de reisende kan stole på og bli glad i.`}</p>
    <BaseCardDesignEntur mdxType="BaseCardDesignEntur">
  <StrongText mdxType="StrongText">Denne manualen er en veiledning til deg som skal ta identiteten i bruk.</StrongText>
  <UnorderedList mdxType="UnorderedList">
    <ListItem mdxType="ListItem">I introduksjonen blir kjent med hvem Entur er, hvorfor vi er til, hva vi tror på, og hva vi gjør for å oppnå målene våre.</ListItem>
    <ListItem mdxType="ListItem">I verktøykassen får du oversikt over alle virkemidlene identiteten er bygget opp av, og hvordan du bruker dem.</ListItem>
  </UnorderedList>
    </BaseCardDesignEntur>
    <h3 {...{
      "id": "bruk-av-enturs-merkevare"
    }}>{`Bruk av Enturs merkevare`}</h3>
    <p>{`Disse retningslinjene er utarbeidet som guide til hvordan vår merkevare og visuelle identitet skal brukes i alt fra trykksaker, markedskommunikasjon til digitale tjenester.`}</p>
    <DoDontGroup mdxType="DoDontGroup">
  <DoDontCard fluidSource={props.data.principles.images.find(image => image.name === 'prinsipp1').childImageSharp.fluid} alt="" variant="none" title="1. Ydmyk for oppdraget" noPadding mdxType="DoDontCard">
    <Paragraph mdxType="Paragraph">
      Vi vet at ved å samarbeide med andre, og spille på hverandres styrker, kan
      vi nå lengre.
    </Paragraph>
  </DoDontCard>
  <DoDontCard fluidSource={props.data.principles.images.find(image => image.name === 'prinsipp2').childImageSharp.fluid} alt="" variant="none" title="2. Innholdet er helten" noPadding mdxType="DoDontCard">
    <Paragraph mdxType="Paragraph">
      Vi løfter frem innholdet ved å synliggjøre og spille tilbyderne gode. Vi
      har ikke behov for å hevde oss og setter andre før oss selv.
    </Paragraph>
  </DoDontCard>
  <DoDontCard fluidSource={props.data.principles.images.find(image => image.name === 'prinsipp3').childImageSharp.fluid} alt="" variant="none" title="3. Flere enn en" noPadding mdxType="DoDontCard">
    <Paragraph mdxType="Paragraph">
      Det som gjør tjenestene våre unike er mangfoldigheten og helheten. Derfor
      skal vi alltid søke etter å tilrettelegge og inkludere våre
      samarbeidspartnere, og vise verdien av å bidra.
    </Paragraph>
  </DoDontCard>
  <DoDontCard fluidSource={props.data.principles.images.find(image => image.name === 'prinsipp4').childImageSharp.fluid} alt="" variant="none" title="4. Enkel og skalerbar" noPadding mdxType="DoDontCard">
    <Paragraph mdxType="Paragraph">
      Identiteten vår er bygget på enkle, tydelige verktøy. Ved å bruke
      verktøykassen på en smart måte, skaper vi et fleksibelt og gjenkjennelig
      uttrykk.
    </Paragraph>
  </DoDontCard>
  <DoDontCard fluidSource={props.data.principles.images.find(image => image.name === 'prinsipp5').childImageSharp.fluid} alt="" variant="none" title="5. Tydelige kontraster" noPadding mdxType="DoDontCard">
    <Paragraph mdxType="Paragraph">
      Vi tydeliggjør historiefortellingen vår ved å spille på kontraster i
      farger, typografi og layout. Dermed gjør vi det enklere for de reisende å
      forstå.
    </Paragraph>
  </DoDontCard>
  <DoDontCard fluidSource={props.data.principles.images.find(image => image.name === 'prinsipp6').childImageSharp.fluid} alt="" variant="none" title="6. Lett og leken" noPadding mdxType="DoDontCard">
    <Paragraph mdxType="Paragraph">
      Vi gjør det enklere for de reisende å velge kollektivt og identiteten vår
      skal alltid uttrykke dette.
    </Paragraph>
  </DoDontCard>
    </DoDontGroup>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      